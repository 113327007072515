import React from "react"
import { AiFillGithub } from "react-icons/ai"
import { MdOpenInNew } from "react-icons/md"
import Layout from "../layout/layout"
import Wrapper from "../components/wrapper"

export default function ProjectTemplate({ pageContext: { project } }) {
  const { html, frontmatter } = project
  const { title, description, live, code } = frontmatter
  return (
    <Layout title={title} description={description}>
      <section>
        <Wrapper>
          <div className="hero">
            <h1>{title}</h1>
            <div className="hero__actions">
              {live ? (
                <a
                  href={live}
                  target="_blank"
                  rel="noreferrer"
                  className="hero__link"
                >
                  Live <MdOpenInNew size={32} />
                </a>
              ) : null}

              <a
                href={code}
                target="_blank"
                rel="noreferrer"
                className="hero__link"
              >
                Code
                <AiFillGithub size={32} />
              </a>
            </div>
          </div>
          <strong>{description}</strong>
        </Wrapper>
      </section>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Layout>
  )
}
